import React from "react";
import Heading from "../components/Heading";
import { IoIosPaperPlane } from "../components/Icons";
import contact from "../data/contact";
import { Tooltip } from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import styles from "../components/Social.module.css";

const Contact = () => {

  return (
    <section id="contact">
      <Heading icon={IoIosPaperPlane} title="Contact" />
      
    <div className={styles.container}>
      {contact.map((x, i) => {
        const Icon = x.icon;

        return (
          <Tooltip key={x.title} title={x.title} placement="bottom">
            <OutboundLink
              href={x.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`${x.class} animated fadeIn`}
              style={{ animationDelay: `${i * 0.25 + 0.25}s` }}
            >
              <Icon size="2em" />
              <span className="sr-only">{x.title}</span>
            </OutboundLink>
          </Tooltip>
        );
      })}
    </div>
    </section>
  );
};

export default Contact;
