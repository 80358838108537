import React from "react";
// import Heading from "../components/Heading";
// import { useStaticQuery, graphql } from "gatsby";
// import Button from "../components/Button";

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     markdownRemark(frontmatter: { id: { eq: "about-rotw" } }) {
  //       html
  //     }
  //   }
  // `);

  return (
    <section id="footer">
      <div className="pt-24 text-xs leading-relaxed opacity-25">
        <div>Licensed under MIT.</div>
        <div>Copyright {new Date().getFullYear()} Amruth Pillai.</div>
        {/* <div>Made with Love in Bengaluru, India.</div> */}
      </div>
    </section>
  );
};

export default Footer;
