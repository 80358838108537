import { FaWhatsapp, FaPhone, FaMailBulk } from "react-icons/fa";

const contact = [
    {
        title: "Whatsapp",
        icon: FaWhatsapp,
        link: "https://api.whatsapp.com/send?phone=918801517109",
        class: "bg-whatsapp",
    },{
        title: "Email",
        icon: FaMailBulk,
        link: "mailto://krngd2@gmail.com"
    },{
        title: "Email",
        icon: FaPhone,
        link: "tellto://+918801517109",
        class: "bg-phone",
    },
]

export default contact;